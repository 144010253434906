import { FC } from 'react';
import {
  generatePath, useParams, Navigate, Outlet
} from 'react-router-dom';
import { getI18n } from 'react-i18next';
import { availableLanguages, defaultLanguage } from './constants';
import { useUserAuth } from '../UserAuth';

export const LanguageGuard: FC = () => {
  const i18n = getI18n();
  const { userData } = useUserAuth();
  const { lang } = useParams<{ lang: string }>();

  const usersLocalLanguage = localStorage.getItem('usersLocalLanguage');
  const usersProfile = userData?.profile;

  // Wait for the user's profile to be loaded before redirecting.
  // Sometimes the user's locale is not in their profile.
  if (!usersProfile) {
    return null;
  }
  const userLang = usersLocalLanguage || usersProfile.locale || defaultLanguage;
  if (!lang) {
    if (!availableLanguages.includes(userLang)) {
      i18n.changeLanguage(defaultLanguage);
      return (
        <Navigate
          to={generatePath('/:lang', { lang: defaultLanguage })}
          replace
        />
      );
    }

    i18n.changeLanguage(userLang);
    return <Navigate to={generatePath('/:lang', { lang: userLang })} replace />;
  }

  return <Outlet />;
};
