import { Navigate } from 'react-router';
import { getI18n } from 'react-i18next';
import { useUserAuth } from '../containers/UserAuth';
import { defaultLanguage } from '../containers/Language';

const RootGuard = () => {
  const i18n = getI18n();
  const { userData } = useUserAuth();

  const usersLocalLanguage = localStorage.getItem('usersLocalLanguage');
  const usersProfile = userData?.profile;

  // Wait for the user's profile to be loaded before redirecting.
  if (!usersProfile) {
    return null;
  }

  const lang = usersLocalLanguage || usersProfile.locale || defaultLanguage;
  i18n.changeLanguage(lang);
  return (
    <Navigate
      to={`/${window.location.href.replace(window.location.origin, lang)}`}
    />
  );
};

export default RootGuard;
