import { useCallback } from 'react';

type Result = {
  setTitle: (title: string) => void;
};

const useDocumentTitle = (): Result => {
  const setTitle = useCallback((title: string) => {
    document.title = title;
  }, []);

  return {
    setTitle
  };
};

export default useDocumentTitle;
