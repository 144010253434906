import { FC } from 'react';
import { useLanguage } from '../../containers/Language';
import Page from '../../components/ui/Page';

const ErrorPage: FC = () => {
  const { t } = useLanguage('translation', { keyPrefix: 'pages.error' });

  return (
    <Page title={t('title')}>
      <p>{t('description')}</p>
    </Page>
  );
};

export default ErrorPage;
