import { JwtPayload } from 'jwt-decode';

export interface TokenPayload extends JwtPayload {
  tenant: string[];
}

/**
 * User roles set in the token by Keycloak under realm_access.roles
 * Should be in sync with the roles defined in the backend by the @visi_one/module-bootstrap package
 */
export enum Role {
  /**
   * Super admin role
   * @description This role allows the user to configure tenants, add priceboard devices, etc.
   */
  SUPER_ADMIN = 'SuperAdmin',
  FREE_TIER = 'FreeTier'
}
