import React from 'react';
import { createInstance } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { availableLanguages, defaultLanguage } from './constants';
import getEnvironment from '../../util/getEnvironment';

interface Props {
  children: React.ReactElement;
}
const environment = getEnvironment();

const i18n = createInstance({
  fallbackLng: defaultLanguage,
  supportedLngs: availableLanguages,
  load: 'languageOnly',
  debug: environment !== 'production',
  detection: {
    order: ['path']
  }
});

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init();

export const LanguageProvider = ({ children }: Props): React.ReactElement => (
  <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
);
