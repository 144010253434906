import React, { useEffect } from 'react';
import {
  Container, Skeleton, Stack, Typography
} from '@mui/material';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import theme from '../../../theme';

interface Props {
  id?: string;
  children?: React.ReactElement;
  disableGutters?: boolean;
  isLoading?: boolean;
  title?: string;
  style?: React.CSSProperties;
}

const Page = ({
  id,
  children,
  disableGutters = false,
  isLoading = false,
  title = '',
  style
}: Props): React.ReactElement => {
  const { setTitle } = useDocumentTitle();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  return (
    <Container id={id} disableGutters={disableGutters} maxWidth={false} style={{
      display: 'flex',
      flexGrow: 1
    }}>
      <Stack spacing={theme.spacing(2)} marginY={theme.spacing(2)} width="100%">
        <Typography marginY={2} variant="h5" sx={style}>
          {isLoading ? <Skeleton /> : title}
        </Typography>
        {isLoading ? <Skeleton /> : children}
      </Stack>
    </Container>
  );
};

export default Page;
