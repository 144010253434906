import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import * as Sentry from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';
import Hotjar from '@hotjar/browser';
import Providers from './providers';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './pages/ErrorPage';
import getEnvironment from './util/getEnvironment';

const environment = getEnvironment();

try {
  if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration()
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/*app\.autotwin\.com\/api/
      ],
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0
    });
  } else {
    console.warn('Sentry DSN is not defined');
  }
} catch (error) {
  console.error('Error initializing Sentry:', error);
}

if (process.env.REACT_APP_BACKEND_MOCKS_ENABLE === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./api/mocks/browser.worker');
  worker.start({ onUnhandledRequest: 'bypass' });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

posthog.init('phc_OzFJp9KJFyRCZTARof3UgfUJWUpf61isIxwGEn3g9iZ', {
  api_host: 'https://eu.i.posthog.com',
  person_profiles: 'identified_only'
});

const siteId = 5122405;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

root.render(
  <React.StrictMode>
    {/* Function component can't be passed to the fallback prop directly. */}
    <Sentry.ErrorBoundary fallback={() => <ErrorPage />}>
      <Providers>
        <PostHogProvider client={posthog}>
          <>
            <CssBaseline />
            <App />
          </>
        </PostHogProvider>
      </Providers>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
